<template>
    <div class="hidden-sm-and-down">
        <v-btn
                text
                router
                exact
                color="black"
                v-for="link in links"
                :key="link.name"
                :to="link.route"
        >{{link.name}}</v-btn>
    </div>
</template>

<script>
    export default {
        name: "NavHeader",
        computed: {
            links() {
                return this.$store.getters.getLink
            }
        }
    }
</script>

<style scoped>

</style>
