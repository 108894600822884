<template>
    <v-list>
        <v-list-item
                v-for="link in links"
                :key="link.name"
                :to="link.route"
                router
                exact
        >
            <v-list-item-content>
                <v-list-item-title v-text="link.name" />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        name: "NavDrawer",
        computed: {
            links() {
                return this.$store.getters.getLink
            }
        }

    }
</script>

<style scoped>

</style>