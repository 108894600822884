<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="12">
        <!--                <v-card class="mb-2" color="background">-->

        <!--                    <v-card-title color="primary">Составление плана лечения - онлайн!</v-card-title>-->
        <!--                    <v-card-text>-->
        <!--                <img src="../../assets/magick.jpg" max-width="350px" height="220px" style="float: left; margin-right: 20px;">  <p>Клиника АБРИС вводит новый сервис для своих пациентов: теперь вы можете не выходя из дома получить-->
        <!--                    первичную консультацию стоматолога - план лечения. Он поможет вам принять решение , спланировать-->
        <!--                    расходы, сэкономить время и деньги на первое посещение клиники.</p>-->

        <!--                        <p>ВАЖНО! Для того, чтобы получить план лечения:</p>-->

        <!--                        <p> 1. Пришлите на почту клиники снимок КТ (компьютерной томографии). Почта: <a href="mailto:jla49@mail.ru">jla49@mail.ru</a></p>-->

        <!--                        <p> 2. Задайте свой вопрос (обязательно!!!) - какого плана консультацию вы хотите получить?</p>-->

        <!--                        <p><em> <b>Например:</b> "Хочу получить полный план лечения с обозначением всех проблем" или "Мне нужно вставить-->
        <!--                        передние зубы. Возможно ли это?" и т.д.</em></p>-->

        <!--                        <p>3 .Если вас интересует <router-link to="/news/post/11"> оплата в рассрочку (без участия банка!)</router-link> - укажите это в письме.</p>-->

        <!--                        <p>4. Напишите свои данные: ФИО, номер телефона.</p>-->

        <!--                        <p><strong>В течение 24 часов вы получите план лечения и расчет по стоимости!</strong></p>-->

        <!--                <p>Также вы сможете сразу получить информацию о возможностях лечения в рассрочку (без участия банка!)-->
        <!--                    и примерном графике выплат.</p>-->

        <!--                        <p> ХОТИТЕ ПОЛУЧИТЬ ПЛАН ЛЕЧЕНИЯ, НЕ ВЫХОДЯ ИЗ ДОМА? Мы вам поможем!</p>-->

        <!--                        <p ><b>ВНИМАНИЕ:</b> План лечения составляется ТОЛЬКО при условии соблюдения пациентом всех обязательных условий:-->
        <!--                    наличие снимка КТ, личные данные (ФИО, телефон) и уточняющего вопроса в письме.</p>-->
        <!--                    </v-card-text>-->

        <!--       </v-card>-->
        <!--                <v-card>-->

        <div
          class="d-flex flex-column flex-wrap justify-center text-center"
          style="height: 150px; background-color: #f5b93f; color: black"
        >
          <v-col><span class="text-h6">Наши акции </span></v-col>
          <v-col>
            Клиника «Абрис»
            <a
              style="
                color: black;
                text-decoration: none;
                background-color: #f5b93f;
              "
              href="tel:+79137316530"
              >+7 913-731-65-30</a
            >
            <a
              style="
                color: black;
                text-decoration: none;
                background-color: #f5b93f;
              "
              href="tel:83832302008"
            >
              (383) 230-20-08</a
            >
          </v-col>
        </div>

        <v-card class="pa-2">
          <v-col class="mb-2">
            <v-card color="primary">
              <v-row align="center">
                <!--                      <div class="d-flex flex-wrap  align-center">-->
                <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                  <v-img
                    class="ma-3"
                    aspect-ratio="2"
                    src="../../assets/2.jpg"
                    max-width="350px"
                    height="220px"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div style="width: 100%">
                    <p class="text-h5 text-center">
                      Профессиональная чистка зубов
                    </p>
                    <p class="text-h6 text-center">3500 рублей</p>

                    <v-card-subtitle class="text-center"></v-card-subtitle>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div class="d-flex justify-center align-center ma-3">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          style="color: black"
                          color="primary"
                          :to="`/service/post/8`"
                        >
                          Подробнее
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-dialog width="500px" v-model="requestDialog">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              style="color: black"
                            >
                              Записаться на прием
                            </v-btn>
                          </template>
                          <app-request
                            @closeRequest="requestDialog = false"
                          ></app-request>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col class="mb-2">
            <v-card color="primary">
              <v-row align="center" no-gutters>
                <!--                      <div class="d-flex flex-wrap  align-center">-->
                <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                  <v-img
                    class="ma-3 justify-center"
                    aspect-ratio="2"
                    src="../../assets/3.jpg"
                    max-width="350px"
                    height="220px"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div style="width: 100%">
                    <p class="text-h5 text-center">
                      Оплата лечения , протезирования , имплантации
                    </p>
                    <p class="text-h6 text-center">
                      В рассрочку БЕЗ УЧАСТИЯ БАНКА
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div class="d-flex justify-center align-center ma-3">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          style="color: black"
                          color="primary"
                          :to="`/news/post/11?yclid=549934701746810`"
                        >
                          Подробнее
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-dialog width="500px" v-model="requestDialog">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              style="color: black"
                            >
                              Записаться на прием
                            </v-btn>
                          </template>
                          <app-request
                            @closeRequest="requestDialog = false"
                          ></app-request>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="mb-2">
            <v-card color="primary">
              <v-row align="center">
                <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                  <v-img
                    class="ma-3"
                    src="../../assets/1.jpg"
                    aspect-ratio="2"
                    max-width="350px"
                    height="220px"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div style="width: 100%">
                    <p class="text-h5 text-center">
                      Импланты Германия Medentika.
                    </p>

                    <p class="text-h6 text-center">28000 рублей</p>

                    <!-- <p class="text-h6 text-center">
                        Прием ведет имплантолог Летягин Тарас Андреевич</p> -->
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div class="d-flex justify-center align-center ma-3">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          style="color: black"
                          color="primary"
                          :to="`/service/post/16`"
                        >
                          Подробнее
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-dialog width="500px" v-model="requestDialog">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              style="color: black"
                            >
                              Записаться на прием
                            </v-btn>
                          </template>
                          <app-request
                            @closeRequest="requestDialog = false"
                          ></app-request>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="mb-2">
            <v-card color="primary">
              <v-row align="center">
                <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                  <v-img
                    class="ma-3"
                    src="../../assets/1.jpg"
                    aspect-ratio="2"
                    max-width="350px"
                    height="220px"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div style="width: 100%">
                    <p class="text-h5 text-center">Импланты Корея Оssten.</p>

                    <p class="text-h6 text-center">24500 рублей</p>

                    <!-- <p class="text-h6 text-center">
                        Прием ведет имплантолог Летягин Тарас Андреевич</p> -->
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div class="d-flex justify-center align-center ma-3">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          style="color: black"
                          color="primary"
                          :to="`/service/post/14`"
                        >
                          Подробнее
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        class="d-flex justify-center align-center"
                      >
                        <v-dialog width="500px" v-model="requestDialog">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              style="color: black"
                            >
                              Записаться на прием
                            </v-btn>
                          </template>
                          <app-request
                            @closeRequest="requestDialog = false"
                          ></app-request>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!--                      <v-col>-->
          <!--                        <v-card-->
          <!--                            color="primary"-->

          <!--                        >-->
          <!--                          <div class="d-flex flex-no-wrap  align-center">-->
          <!--                            <v-avatar-->
          <!--                                class="ma-3"-->
          <!--                                size="350"-->
          <!--                                tile-->
          <!--                            >-->
          <!--                              <v-img src="../../assets/2.jpg"></v-img>-->
          <!--                            </v-avatar>-->
          <!--                            <div style="width: 100%">-->
          <!--                              <p class="text-h5 text-center">-->
          <!--                                Профессиональная чистка зубов-->
          <!--                              </p>-->
          <!--                              <p-->
          <!--                                  class="text-h6 text-center"-->
          <!--                              >2500 рублей</p>-->

          <!--                              <v-card-subtitle class="text-center">Акция продлится до 30.11.2022</v-card-subtitle>-->

          <!--                            </div>-->

          <!--                            <div class=" d-flex justify-center align-center ma-3">-->

          <!--                              <v-dialog-->
          <!--                                  width="500px"-->
          <!--                                  v-model="requestDialog"-->
          <!--                              >-->
          <!--                                <template v-slot:activator="{on, attrs}">-->
          <!--                                  <v-btn-->
          <!--                                      color="primary"-->
          <!--                                      v-bind="attrs"-->
          <!--                                      v-on="on"-->
          <!--                                      style="color: black "-->
          <!--                                  >-->
          <!--                                    Записаться на прием-->
          <!--                                  </v-btn>-->
          <!--                                </template>-->
          <!--                                <app-request @closeRequest="requestDialog = false"></app-request>-->
          <!--                              </v-dialog>-->

          <!--                            </div>-->
          <!--                          </div>-->

          <!--                        </v-card>-->
          <!--                      </v-col>-->
          <!--                      <v-col>-->
          <!--                        <v-card-->
          <!--                            color="primary"-->

          <!--                        >-->
          <!--                          <div class="d-flex flex-no-wrap align-center">-->
          <!--                            <v-avatar-->
          <!--                                class="ma-3"-->
          <!--                                size="350"-->
          <!--                                tile-->
          <!--                            >-->
          <!--                              <v-img src="../../assets/3.jpg"></v-img>-->
          <!--                            </v-avatar>-->
          <!--                            <div style="width: 100%" >-->
          <!--                              <p class="text-h5 text-center">-->
          <!--                                Оплата лечения , протезирования , имплантации</p>-->
          <!--                              <p class="text-h6 text-center">В рассрочку БЕЗ УЧАСТИЯ БАНКА</p>-->

          <!--&lt;!&ndash;                              <v-card-subtitle  class="text-center">Акция продлится до 31.11.2022</v-card-subtitle>&ndash;&gt;-->

          <!--                            </div>-->

          <!--                            <div class="d-flex justify-center align-center ma-3">-->

          <!--                              <v-dialog-->
          <!--                                  width="500px"-->
          <!--                                  v-model="requestDialog"-->
          <!--                              >-->
          <!--                                <template v-slot:activator="{on, attrs}">-->
          <!--                                  <v-btn-->
          <!--                                      color="primary"-->
          <!--                                      v-bind="attrs"-->
          <!--                                      v-on="on"-->
          <!--                                      style="color: black "-->
          <!--                                  >-->
          <!--                                    Записаться на прием-->
          <!--                                  </v-btn>-->
          <!--                                </template>-->
          <!--                                <app-request @closeRequest="requestDialog = false"></app-request>-->
          <!--                              </v-dialog>-->

          <!--                            </div>-->
          <!--                          </div>-->

          <!--                        </v-card>-->
          <!--                      </v-col>-->

          <!--                  </v-card>-->

          <!--            <v-toolbar-->
          <!--                    color="primary"-->
          <!--                    dark-->
          <!--                    flat-->
          <!--            >-->
          <!--                <v-toolbar-title>Выгодные предложения</v-toolbar-title>-->
          <!--                <template v-slot:extension>-->
          <!--                    <v-tabs-->
          <!--                            v-model="model"-->
          <!--                            centered-->
          <!--                            slider-color="yellow"-->
          <!--                    >-->
          <!--                        <v-tab-->
          <!--                                v-for="item in category"-->
          <!--                                :key="item.id"-->
          <!--                                :href="`#tab-${item.id}`"-->
          <!--                        >-->
          <!--                            {{item.category}}-->
          <!--                        </v-tab>-->
          <!--                    </v-tabs>-->
          <!--                </template>-->
          <!--            </v-toolbar>-->
          <!--            <v-tabs-items v-model="model">-->
          <!--                <v-tab-item-->
          <!--                        v-for="item in category"-->
          <!--                        :key="item.id"-->
          <!--                        :value="`tab-${item.id}`"-->
          <!--                        class="pa-2"-->

          <!--                >-->

          <!--                    <v-row>-->
          <!--                        <v-col cols="12" md="4" v-for="item in sortNews(item.id)" :key="item.id">-->
          <!--                            <v-card height="500px" color="background">-->
          <!--                                <v-img :src="getUrl + item.images"-->
          <!--                                       :lazy-src="getUrl + item.images"-->
          <!--                                       height="250px"-->
          <!--                                       aspect-ratio="2"-->
          <!--                                ></v-img>-->
          <!--                                <v-card-title>{{item.title}}</v-card-title>-->
          <!--                                <v-card-text class=" size-card-text truncate-text">{{item.preview}}</v-card-text>-->
          <!--                                <v-card-actions class="justify-center align-center text-center btn-down"><v-btn color="primary" :to="`/news/post/${item.id}`">Подробнее</v-btn></v-card-actions>-->

          <!--                            </v-card>-->
          <!--                        </v-col>-->
          <!--                    </v-row>-->
          <!--                </v-tab-item>-->
          <!--            </v-tabs-items>-->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import appRequest from "@/components/request/Request";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      model: "tab-1",
      sortedNews: null,
      requestDialog: false,
    };
  },
  mounted() {
    this.$store.dispatch("getCategoryList");
    this.$store.dispatch("getNewsAll");
  },
  computed: {
    ...mapGetters(["getUrl", "category"]),
  },
  methods: {
    sortNews(id) {
      let news = this.$store.getters.news;
      return (news = news.filter((item) => {
        return item.category_id === id;
      }));
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  components: {
    appRequest,
  },
};
</script>

<style scoped>
.size-card-text {
  height: 40px;
  width: 100%;
  overflow: hidden;
}
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.btn-down {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
}
</style>
