
    <template>
        <v-app id="inspire" app light>
            <v-navigation-drawer
                v-model="drawer"
                app
                temporary
            >
            <nav-drawer></nav-drawer>
            </v-navigation-drawer>

            <v-app-bar app light height="50px" color="primary">

                <v-app-bar-nav-icon @click="drawer = !drawer" color="white" class="hidden-md-and-up"></v-app-bar-nav-icon>

                <v-toolbar-title class="display-1 font-weight-thin" ><router-link :to="'/'"><v-img src="../assets/logodarck.png" width="105px" height="50px"></v-img></router-link></v-toolbar-title>
                <v-spacer></v-spacer>
                <nav-header></nav-header>
            </v-app-bar>

            <v-main>
                <router-view></router-view>
            </v-main>
            <nav-footer></nav-footer>
        </v-app>
    </template>

    <script>
        import NavDrawer from "../components/nav/NavDrawer";
        import NavHeader from "../components/nav/NavHeader";
        import NavFooter from "../components/nav/NavFooter";
        import {mapActions,mapGetters} from "vuex"
        export default {
            data: () => ({drawer:null }),
            methods:{
                ...mapActions([]),
            },
            computed:{
                ...mapGetters([

                ])
            },
            components:{
                NavDrawer,
                NavHeader,
                NavFooter
            }
        }
    </script>
    <style scoped>

    </style>

