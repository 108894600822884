<template>
  <v-container fluid>
    <v-card color="#F5B93F" class="mb-3">
      <v-card-title>Наши врачи</v-card-title>
    </v-card>
    <v-card color="#F5B93F" class="mb-3" style="height: 80vh">
      <v-card-text class="d-flex justify-center">
        <div class="text-h3 align-self-center">
          Cтраница находится в разработке
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Doctors",
  data: () => ({}),
};
</script>
