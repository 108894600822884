<template>
  <vue-extend-layouts></vue-extend-layouts>
</template>

<script>
    import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
    components:{
        VueExtendLayouts
    }
};
</script>
<style scoped>

</style>
