<template>
    <v-footer
        fixed
            :padless="padless"
            class="mt-1"
        app
        height="50px"

    >
        <v-card
                flat
                tile
                width="100%"
                class="primary text-center"
        >
            <v-card-actions class="hidden-sm-and-up justify-center align-center">
                <v-btn
                        class="mx-4"
                        icon
                        to="/"
                >
                    <v-icon size="24px">
                        mdi-home
                    </v-icon>
                </v-btn>
                <v-dialog
                        width="500px"
                        v-model="requestDialog"
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                                class="mx-4"
                                icon
                                v-bind="attrs"
                                v-on="on"

                        >
                            <v-icon size="24px">
                                mdi-email
                            </v-icon>
                        </v-btn>
                    </template>
                    <app-request @closeRequest="requestDialog = false"></app-request>
                </v-dialog>
                <v-btn
                        class="mx-4"
                        icon
                        to="/contacts"
                >
                    <v-icon size="24px">
                       mdi-map-marker
                    </v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-actions class="text--black hidden-sm-and-down justify-space-between">
                <a href="/Police" style="color:#000000; text-decoration: none">Политика конфедициальности</a>
                <div class="d-flex flex-column">
                <span class="text-caption">2008 - {{ new Date().getFullYear() }} — Абрис стоматология </span>
                <span  class="text-caption">ООО "Дентал плюс" лицензия "ЛО-54-003091"</span>
                </div>
            </v-card-actions>

        </v-card>
    </v-footer>
</template>

<script>
    import appRequest from "../request/Request";
    export default {
        name: "NavFooter",
        data: () => ({
            requestDialog: false,
            padless: true,


        }),
        components:{
            appRequest
        }
    }
</script>

<style scoped>

</style>
