<template>
    <div>
    <v-card class="mb-3" color="#F5B93F">
        <v-card-title >Лицензии</v-card-title>
    </v-card>
        <v-card color="background">
        <v-card-text>
            <v-row>
                <v-col
                        v-for="image in gallery"
                        :key="image.id"
                        class="d-flex child-flex"
                        cols="4"
                >
                    <v-img
                            :src='getUrl+image.images'
                            :lazy-src='getUrl+image.images'
                            contain
                            class="white"
                            @click="openImg(image)"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                            >
                                <v-progress-circular
                                        indeterminate
                                        color="primary"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog
        max-width="600px"
        v-model="galleryDialog"
        >
            <v-card color="background">
                <v-card-actions>
                    <v-spacer></v-spacer>   <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-actions>
                <v-img
                        :src="getUrl + image"
                        :lazy-src="getUrl + image"
                        contain
                        aspect-ratio="0.7"
                        max-height="800px"
                />


            </v-card>
        </v-dialog>
    </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapActions, mapGetters} from "vuex"
    export default {
        name: "gallery",
        data(){
            return{
                gallery:[],
                image:'',
                galleryDialog: false
            }

        },
        created() {
            this.loadLicense()
        },
        methods:{
            async  loadLicense(){
                await  axios.get('/api/gallery/1')
                    .then(response =>{
                        this.gallery = response.data.data
                    })
                    .catch(error => console.log(error))
            },
            openImg(image){
                this.galleryDialog=true
                this.image = image.images
                console.log(image)
            },
            close(){
                this.galleryDialog = false
            }

        },
        computed:{
            ...mapGetters([
                "getUrl"
            ])


    }

    }
</script>

<style scoped>

</style>
