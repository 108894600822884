<template>
<div>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12">
                <v-card height="80vh">
                    <v-img src="../assets/abris.jpg"
                           min-height="100%"
                           max-height="780px"
                           aspect-ratio="16/9"
                    >
                        <v-row
                            align="center" justify="end" class="fill-height"
                        >
                            <div align="end" justify="end" class="ma-5" max-width="320px" style="margin-right:40px;">
                                <v-img src="../assets/logodarck.png" max-width="320px" contain class="ml-auto"></v-img>

                                <h3>РАССРОЧКА БЕЗ УЧАСТИЯ БАНКА!</h3>
                                <h4>ИМПЛАНТАЦИЯ, ПРОТЕЗИРОВАНИЕ, ЛЕЧЕНИЕ и БРЕКЕТЫ</h4>
                                <p>Вы можете оплатить услуги клиники без переплаты​, без участия банка, <br/>
                                    в рассрочку на несколько месяцев.</p>
                                <p>Первоначальный взнос всего 30% до 50%.</p>
                                ​
                                <v-btn
                                    color="primary"
                                    class="mr-3"
                                    href="#price"
                                >
                                    Наш прайс
                                </v-btn>
                                <v-dialog
                                    width="500px"
                                    v-model="requestDialog"
                                >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            Записаться на прием
                                        </v-btn>
                                    </template>
                                    <app-request @closeRequest="requestDialog = false"></app-request>
                                </v-dialog>

                            </div>
                        </v-row>


                    </v-img>
                </v-card>
            </v-col>
        </v-row>


    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12">
            <v-card >
                <v-card-text>
                    <v-card color="background" class="mb-3">
                    <v-card-title class="justify-center align-center">Красивая улыбка: инвестиции в себя</v-card-title>
                        <v-card-text>

                            <p>  <img src="../assets/cdc0f8_1d6b8ef5b571434b8fd82fc92d5ee2d1~mv2.jpg" class="mr-2"  height="250px" style="float: left">
                                Даже сейчас, когда современные технологии в стоматологии априори подразумевают любые манипуляции без боли, многие пациенты все-таки боятся врачей как огня. И именно поэтому откладывают визиты к стоматологу.
                                Но, к сожалению, такие отсрочки не проходят бесследно: ситуация, как правило, ухудшается. Лечить и восстанавливать зубы приходится гораздо дольше и дороже.</p>

                           <p> Многие люди, узнав стоимость своего "стоматологического проекта", решают, что это дорого и не спешат тратить деньги.
                               Но если посмотреть на это с другой стороны: ведь это не трата, а инвестиция. Самая настоящая инвестиция в себя: в свое здоровье, в свой успех, свою красоту. И это та самая инвестиция, которая окупается сполна.</p>

                            <p>Конечно, важно обращать внимание на цены, качество, гарантии. На опыт врачей и репутацию клиники. Если вы сомневаетесь в чем-то, если не уверены или не можете принять решение - всегда можно получить альтернативное, второе мнение.
                        Ваша задача и ответственность найти такую клинику, специалистам которой вы сможете доверить самое дорогое, что у вас есть - здоровье!</p>

                        <p>И еще об инвестициях и тратах: в нашей клинике вы можете получить стоматологическую помощь в РАССРОЧКУ БЕЗ УЧАСТИЯ БАНКА. А это значит - без дополнительных процентов, сроком до полугода (а в особых случаях - сроки могут быть больше).
                            Чтобы узнать подробнее об этой возможности - звоните <a href="tel:83832302008">(383)  230-20-08</a>.</p>

                        </v-card-text>


                    </v-card>
                    <v-card class="mb-3" color="background" >
                        <v-card-title class="justify-center align-center">Обращаем ваше ВНИМАНИЕ на то, что:</v-card-title>
                        <v-divider color="#a0796d"></v-divider>
                        <v-list color="background">
                            <v-list-item>Точную стоимость лечения можно определить только при визите к врачу.</v-list-item>
                            <v-list-item>Только врач может определить необходимость той или иной манипуляции, все составляющие операции и сделать необходимые назначения.</v-list-item>
                            <v-list-item>Все вопросы по ценообразованию вы сможете обсудить с управляющей клиники. Вы получите понятные, грамотные и аргументированные ответы.</v-list-item>
                        </v-list>
                        <v-card-title class="text-center justify-center align-center"> Напоминаем, что оплату за лечение, ИМПЛАНТАЦИЮ и ПРОТЕЗИРОВАНИЕ в нашей клинике вы можете осуществить В РАССРОЧКУ БЕЗ УЧАСТИЯ БАНКА.</v-card-title>
                        <v-card-title class="text-center justify-center align-center"> Первоначальный взнос всего 50%!</v-card-title>
                        <v-card-text class="text-center justify-center align-center">Все подробности можно узнать по телефону <a href="tel:83832302008"> (383) 230-20-08</a></v-card-text>
                    </v-card>
                    <v-card color="background">
                        <v-card-title id="price">Наш прайс лист</v-card-title>
                        <v-card-text>


                            <v-expansion-panels focusable>
                                <v-expansion-panel
                                        v-for="item in category"
                                        :key="item.id"
                                >
                                    <v-expansion-panel-header  color="background">{{item.category}}</v-expansion-panel-header>
                                    <v-expansion-panel-content color="background">


                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">

                                                    </th>
                                                    <th class="text-left">
                                                        Цена
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr
                                                        v-for="item in sortPrice(item.id)"
                                                        :key="item.id"
                                                >
                                                    <td>{{ item.manipulation }}</td>
                                                    <td>{{ item.price }} руб.</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                        </v-card-text>
                        <v-card-text class="mt-2 text-center">
                            <b>ВНИМАНИЕ!</b>
                            Точную стоимость услуг, которые необходимы именно вам, вы сможете узнать на
                            консультации у доктора!
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</div>
</template>

<script>
    import appRequest from '@/components/request/Request';
    import {yandexMap, ymapMarker} from "vue-yandex-maps";
    export default {
        name: "Price",
        data(){
            return {
                requestDialog: false,
            }
        },


        created() {
            this.$store.dispatch('getTableAll')
            this.$store.dispatch('getTableCategory')
        },
        methods:{
            sortPrice(id){
                let priceItem =  this.$store.getters.getPrice

                return priceItem = priceItem.filter(item => {
                    return item.category_id == id
                })


            }


        },
        components:{
            appRequest,

        },
        computed:{
            category(){
                return this.$store.getters.getPriceCategory
            },

        }

    }
</script>

<style scoped>
    tbody tr:nth-of-type(even) {
        background-color: #F9F0E8;
    }
    tbody tr:nth-of-type(odd) {
        background-color: #F9F0E8;
    }
     thead tr th {
        background-color: #F9F0E8;
         color: white;
    }


</style>
