<template>
    <div>
        <v-card color="#F5B93F" class="mb-3">
            <v-card-title>ИНФОРМАЦИЯ ДЛЯ ПАЦИЕНТА</v-card-title>
        </v-card>
    <v-card class="mb-3" color="background">
        <v-card-text>
            <v-card-title>1. Правила записи на первичный прием в ООО «Дентал плюс»:</v-card-title>

            <v-card-text>

            <p>Запись пациента к врачу осуществляется у стойки администратора или по телефонам: +7(383) 230-20-08, +7(913) 731-65-30.</p>

            <p>При первичном обращении в Клинику Пациент должен предъявить паспорт или любой документ, удостоверяющий личность.</p>

            <p>При наличии показаний лечащий врач направляет пациента на стационарное лечение в городские больничные учреждения.</p>

            <p>Повторный приём пациента осуществляется в день и время, назначенное врачом. Неявка на приём в назначенный день влечет правовые последствия в соответствие с Договором на оказание платных медицинских услуг.</p>

            <p>В случае необходимости направления на консультацию в другие лечебные учреждения пациенту выдаётся направление установленного образца и выписка из медицинской карты.</p>
            </v-card-text>
            ​

            <v-card-title>2. Диспансеризация:</v-card-title>

            ​
            <v-card-text>
            Диспансеризация в ООО "Дентал плюс" не проводится.
            </v-card-text>
            ​

            <v-card-title>3. Информация и документы, регулирующие отношения сторон Клиника - Пациент:</v-card-title>
            <v-card-text>
            <v-card-actions class="justify-space-around">
                <v-chip  color="primary" outlined><a class="v-btn v-btn--text" :href="getUrl + 'pdf/contract-medical-services.pdf'" target="_blank">Договор об оказания медицинских услуг</a></v-chip>
                <v-chip  color="primary" outlined> <a class="v-btn v-btn--text" :href="getUrl + 'pdf/THE-BASICS-OF-PROTECTING-THE-HEALTH-OF-CITIZENS.pdf'" target="_blank">Закон об основах охраны здоровья граждан </a></v-chip>
            </v-card-actions>
                <v-card-actions class="justify-space-around">
                    <v-chip  color="primary" outlined> <a class="v-btn v-btn--text" :href="getUrl + 'pdf/medical-services-and-their-payment.pdf'" target="_blank">Правла оказания мед. услуг и их оплаты</a></v-chip>
                        <v-chip  color="primary" outlined> <a class="v-btn v-btn--text" :href="getUrl + 'pdf/free-stom-help.pdf'" target="_blank">О бесплатной стоматологической мед. помощи</a></v-chip>
                </v-card-actions>
            </v-card-text>
            <v-card-title> 4. Телефоны и адреса контролирующих организаций:</v-card-title>

            ​<v-card-text>
            <v-card-text>
                <p>Министерство здравоохранения Новосибирской области</p>

                <p>Красный проспект, 18</p>

                <p>Тел: (383) 222-15-61</p>
            </v-card-text>

        <v-card-text>
            <p>Управление Федеральной службы по надзору в сфере здравоохранения по Новосибирской области (Росздравнадзор)</p>

            <p>Красный проспект, д.11, 1-й этаж</p>

            <p>Тел: (383) 223-23-15</p>
        </v-card-text>

        <v-card-text>
            <p>Территориальное управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Новосибирской области (Роспотребнадзор)</p>

            <p>ул.Челюскинцев, д.7а</p>

            <p>Тел: (383) 220-42-85, 220-26-78</p>
        </v-card-text>

            </v-card-text>
        </v-card-text>
    </v-card>

       <app-gallery></app-gallery>
    </div>
</template>

<script>

    import appGallery from "@/components/images/Gallery"
    import {mapGetters} from "vuex"
    export default {
        name: "info",
        components:{
            appGallery,
        },
        computed:{
            ...mapGetters([
                "getUrl"
            ])



        }
    }
</script>

<style scoped>

</style>
