<template>
    <v-card color="background">
        <v-card-text>
            <v-carousel height="auto" :show-arrows="true" :hide-delimiters="true">
                <v-carousel-item
                        v-for="(item,i) in gallery"
                        :key="i"

                >
                    <v-img
                            max-height="500px"
                            :src="getUrl + item.images"
                            :lazy-src="getUrl + item.images"
                            contain
                           aspect-ratio="2.0"
                    >


                        <template v-slot:placeholder>
                            <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                            >
                                <v-progress-circular
                                        indeterminate
                                        color="primary"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-carousel-item>
            </v-carousel>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from "vuex";

    export default {
        name: "carousel",
        data(){
            return {
                gallery:[]
            }
        },
        created() {
            this.loadGallery()
        },
        methods:{
            async loadGallery(){
                await axios.get('/api/gallery/2')
                    .then(response =>{
                        this.gallery = response.data.data
                    })
                    .catch(error => console.log(error))
            },



        },
        computed:{
            ...mapGetters([
                "getUrl"
            ])


        }
    }
</script>

<style scoped>

</style>
