<template>
            <div class="about-strong">
               <div class="header" color="background">

                   <h3>Почему клиника АБРИС?</h3>

               </div>
                <div class="title">
                    <v-img  src="../assets/logodarck.png" max-height="120px" max-width="200px" contain></v-img>
                </div>

                <v-expansion-panels accordion class="price">
                    <v-expansion-panel>
                        <v-expansion-panel-header align="center" color="#F5B93F">Доступная стоимость</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                            Очень <strong>доступная стоимость</strong> лечения и удобные
                            схемы оплаты услуг​ (рассрочка оплаты без участия банка, например, есть пока только в нашей клинике).
                            Пациенты к нам приезжают из разных концов города, потому что им это финансово выгодно!​
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels accordion class="absolutly">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#F5B93F">Абсолютно понятно и просто объяснять</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                            Мы говорим на человеческом языке. <strong>Абсолютно понятно и просто объяснять</strong>
                            даже самое сложное и непонятное - это "фишка" наших специалистов.
                            Кстати, нас очень любят и студенты, и люди старшего возраста,
                            потому что чувствуют внимательное отношение к себе и легко понимают все объяснения и аргументы врачей.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels accordion class="options">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#F5B93F">Варианты решения</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                            Обязательная возможность выбора. Предложение пациенту <strong>двух (как минимум) вариантов решения</strong>
                            стоматологических проблем и задач - это святое правило консультаций наших специалистов.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels accordion class="proc">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#F5B93F">Варианты есть</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                            <em>В 97% случаев есть варианты, как можно решить стоматологические задачи пациента.
                                Наши врачи постараются обязательно предложить альтернативу для выбора (если только она есть.
                                В ином случае пациенту обязательно объяснят почему альтернатива невозможна).</em></v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels accordion class="official">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#F5B93F">Официальные данные</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                                Наша клиника существует с 2008 года.
                                    Юридическое название клиники ООО "Дентал Плюс".
                                    Обновление лицензии (получение бессрочной лицензии) прошло 03.02.2015 года.
                                 Лицензия ЛО-54-003091
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels accordion class="equment">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#F5B93F">Оснащение</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">

                                Стоматологические материалы для лечения и анестезии - самого высочайшего качества от лучших производителей (производства США, Франции, Японии).
                                Клиника оборудована 3-мя современными стоматологическими креслами, наш хирургический кабинет  соответствует всем строжайшим нормативам.
                                Прием ведется на стоматологическом оборудовании немецкого и азиатского производства.

                         </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>



            </div>





</template>

<script>

    export default {
        name: "about",

    }
</script>

<style scoped>
    .about-strong{
        background-image: url("../assets/abris.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding: 20px;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 40px 160px 50px 160px 50px 160px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-areas: "header header header header header"
                             ". price . options ."
                             " . . . . ."
                              "equment . . . absolutly"
                                 " . . title . ."
                            ". official . proc .";
        justify-items: center;
        align-items: center;
    }
    .header{
        grid-area: header;
    }
    .title{
        grid-area: title;

    }
    .price{
        grid-area: price;
        background-color: #F5B93F;
    }
    .absolutly{
        grid-area: absolutly;
    }
    .options{
        grid-area: options;
    }
    .proc{
        grid-area: proc;
        text-align: center;
    }
    .official{
        grid-area: official;

    }
    .equment{
        grid-area:equment;
    }

</style>
