<template>
    <v-card>
        <v-card-title>Записаться на прием</v-card-title>
        <v-card-text>
            <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
            >
                <v-text-field label="Ваше имя" v-model="message.name" :rules="rules.nameRules"></v-text-field>
                <v-text-field label="Адрес электронной почты" v-model="message.email" :rules="rules.emailRules"></v-text-field>
                <v-text-field label="Телефон" v-model="message.phone"></v-text-field>
                <v-textarea label="Сообщение" v-model="message.textmessage"></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="cancel" @click="$emit('closeRequest')">Отмена</v-btn>
            <v-btn class="primary" @click="sendRequest">Отправить</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "request",
        data(){
            return {
                valid: false,
                message:{
                    name: '',
                    email: '',
                    phone: '',
                    textmessage:'',
                },
                rules:{
                    nameRules: [
                        v => !!v || 'Поле почты не заполнено',
                        v => (v && v.length > 3) || 'Имя должно быть дленее 3 символов',
                    ],
                    emailRules: [
                        v => !!v || 'Поле почты не заполненно',
                        v => /.+@.+\..+/.test(v) || 'Не правильно заполнено поле почты',
                    ],
                }
            }
        },
        methods:{
            sendRequest(){

                const data = new FormData
                data.append('name', this.message.name)
                data.append('email', this.message.email)
                data.append('phone', this.message.phone)
                data.append('textmessage',this.message.textmessage)

                axios.post('/api/user_request',data)
                    .catch(error => console.log(error))
                this.$emit('closeRequest')
            },




        }
    }
</script>

<style scoped>

</style>
