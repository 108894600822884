import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

function loadView(view) {
  return () => import(`../views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: loadView("Home"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/news",
    name: "Nome",
    component: loadView("news/News"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: loadView("Doctors"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/news/post/:id",
    name: "newsItem",
    component: loadView("news/NewsPost"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/service",
    name: "Service",
    component: loadView("service/Service"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/service/post/:id",
    name: "ServicePost",
    component: loadView("service/ServicePost"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: loadView("Contacts"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/price",
    name: "Price",
    component: loadView("Price"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/about",
    name: "About",
    component: loadView("About"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/Police",
    name: "Police",
    component: loadView("Police"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "/Info",
    name: "Info",
    component: loadView("Info"),
    meta: {
      layout: "default",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
