<template>
    <v-card outlined  style="border: 1px solid black;">
        <v-expansion-panels accordion  >
            <v-expansion-panel color="background">
                <v-expansion-panel-header color="background">

                    <v-card-title>{{serviceItemCategory.category}}</v-card-title>

                </v-expansion-panel-header>
                <v-expansion-panel-content color="background">
                    {{serviceItemCategory.description}}

                    <v-list color="background">
                        <v-list-item v-for="post in posts" :key="post.id"  color="background">
                            <v-row justify="center" align="center" color="background">
                            <v-chip
                                class="ma-2"
                                color="primary"
                                outlined
                                :to="`/service/post/${post.id}`"
                            >
                            {{post.title}}
                            </v-chip>
                            </v-row>
                        </v-list-item>
                    </v-list>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


    </v-card>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "cardService",
        props:{
            serviceItemCategory:{
                type: Object,
                default(){
                    return {}
                }
            },
        },

        data(){
            return{
                category_id: this.serviceItemCategory.id,
                posts:[]
            }
        },
        mounted() {
            this.getPostByCategory(this.category_id)
        },
        methods:{
            async getPostByCategory(category_id){
                this.posts = await axios.get(`/api/service/${category_id}`)
                    .then(response => {return response.data.data})
                    .catch(error => console.log(error))
            }
        }

    }
</script>

<style scoped>

</style>
