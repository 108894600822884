<template>
    <v-container fluid>
            <!-- <v-card>
                <v-toolbar
                    flat
                    color="primary"
                    dark
                >
                    <v-toolbar-title>Услуги</v-toolbar-title>
                </v-toolbar>
                <v-tabs vertical  >
                    <v-tab class="justify-left  align-left" v-for="item in services" :key="item.id">
                        {{item.category}}
                    </v-tab>
                    <v-tab-item v-for="item in services"
                                :key="item.id"
                                >
                        <v-card flat>
                            <v-card-title class="justify-center  align-center">
                                {{item.category}}
                            </v-card-title>
                            <v-card-subtitle>{{item.description}}</v-card-subtitle>
                            <v-card-text>
                                <v-row class="justify-center align-center">
                                <v-col cols="12" md="4" v-for="item in sortService(item.id)"
                                       :key="item.id">
                                    <v-card class="mb-3" height="400px">
                                        <v-img
                                            :src="getUrl + item.img"
                                            height="200"
                                            aspect-ratio="2"
                                        ></v-img>
                                        <v-card-title>{{item.title}}</v-card-title>
                                        <v-card-text class="size-card-text truncate-text">{{item.preview}}</v-card-text>
                                        <v-card-actions class="justify-center align-center btn-down">
                                            <v-btn
                                                color="primary"
                                                :to="`/service/post/${item.id}`"
                                            >
                                                Узнать
                                            </v-btn>

                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card> -->

            <v-card>
            <v-toolbar
                    color="primary"
                    dark
                    flat
            >
                <v-toolbar-title>Услуги</v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs
                            v-model="model"
                            centered
                            slider-color="yellow"
                    >
                        <v-tab
                                v-for="item in services"
                                :key="item.id"
                                :href="`#tab-${item.id}`"
                        >
                            {{item.category}}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-tabs-items v-model="model">
                <v-tab-item
                        v-for="item in services"
                        :key="item.id"
                        :value="`tab-${item.id}`"
                        class="pa-2"

                >

                    <v-row>
                        <v-col cols="12" md="4" v-for="item in sortService(item.id)" :key="item.id">
                            <v-card height="500px" color="background">
                                <v-img 
                                       :src="getUrl + item.img"
                                            height="200"
                                            aspect-ratio="2"
                                ></v-img>
                                <v-card-title>{{item.title}}</v-card-title>
                                <v-card-text class=" size-card-text truncate-text">{{item.preview}}</v-card-text>
                                <v-card-actions class="justify-center align-center text-center btn-down"><v-btn color="primary" :to="`/service/post/${item.id}`">Подробнее</v-btn></v-card-actions>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

    </v-container>
</template>

<script>
    import appCard from "@/components/service/Card";
    import axios from "axios";

    export default {
        name: "index",
        data(){
           return{
               model: 'tab-1',

           }
    },
        components:{
            appCard
        },
        created() {
            this.$store.dispatch('getServiceCategoryApi')
            this.$store.dispatch('getServicePost')
        },
        methods:{
            sortService(id) {
                let news = this.$store.getters.getServicePost
                return news = news.filter(item => {
                    return item.category_id === id
                })
            }
        },
        computed:{
            services(){
                return this.$store.getters.getServiceCategory
            },
            servicesPosts(){
                return this.$store.getters.getServicePost
            },
            getUrl(){
                return this.$store.getters.getUrl
            }

        },
    }
</script>

<style scoped>
    .size-card-text{
        height: 40px;
        width: 100%;
        overflow: hidden;
    }
    .truncate-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .btn-down{
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 20px;
    }


</style>
