<template>
<v-container fluid>
    <v-row>
        <v-col sm="12" md="12">

            <v-card class="mb-3" color="background">
                <v-card-title class="justify-center align-center">{{post.title}}</v-card-title>
                <v-card-actions class="justify-space-between">
                    <v-icon @click="hasHistory()
                                 ? $router.go(-1)
                                 : $router.push('/')">mdi-arrow-left</v-icon>



                </v-card-actions>
            </v-card>

            <v-card class="mt-3" color="background">

                    <v-card-title>
                    <v-row justify="center" align="center">
                        <v-img :src="getUrl + post.img"
                               :lazy-src="getUrl + post.img"
                               max-width="800px"
                               max-height="600px"
                               aspect-ratio="2"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-row>
                    </v-card-title>
                <v-row align="center" justify="center" >
                    <v-col sm="12" md="8">
                    <v-card-text v-html="post.content_post">
                    </v-card-text>
                        <v-divider></v-divider>
                    <v-card-actions class="d-flex justify-center">

                        <v-btn color="primary" class="justify-center" to="/price">Наши цены</v-btn><v-dialog
                        width="500px"
                        v-model="requestDialog">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="ml-3"
                            >
                                Записаться на прием
                            </v-btn>
                        </template>
                        <app-request @closeRequest="requestDialog=false"></app-request>
                    </v-dialog>
                    </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>



        </v-col>
    </v-row>
</v-container>
</template>



<script>
    import axios from "axios";
    import appRequest from '@/components/request/Request';
    import {mapGetters, mapActions} from "vuex"
    export default {
        name: "ServicePost",
        data(){
            return{
                requestDialog: false,
                post : [],
            }
        },
        mounted() {
            this.getPostById(this.$route.params.id)
        },
        methods:{
            async getPostById(id){
                this.post = await axios.get(`/api/service/post/${id}`)
                    .then(response => {return response.data})
                    .catch(error => console.log(error))

            },
            hasHistory () {
                return window.history.length > 2
            }

        },
        computed:{
           ...mapGetters([
               "getUrl",
               "getSortServiceCategory",
           ])
        },
        components:{
            appRequest
        }
    }
</script>

<style scoped>

</style>
