import axios from "axios";
export  default {
    state:{
        priceCategory:null,
        price: null
    },
    mutations:{
        setPrice(state, data){
            state.price = data
        },
        setPriceCategory(state,data){
            state.priceCategory = data
        }

    },
    actions:{
        async getTableAll({commit}){
                await axios.get('/api/prices' )
                    .then(response => {
                        commit('setPrice', response.data.data)
                    })
                    .catch(error => console.log(error))
        },
        async getTableCategory({commit}){

                await axios.get('/api/price_category')
                         .then(response => {
                             commit('setPriceCategory', response.data.data)
                         })
                        .catch(error => console.log(error))
        }

    },
    getters:{
        getPrice(state){
            return state.price
        },
        getPriceCategory(state){
            return state.priceCategory
        },

    }

}