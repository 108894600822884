<template>
<div class="mt-2">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12"  md="12">

            <v-card height="100vh">
                <v-img src="../assets/abris.jpg"
                       height="100%"

                aspect-ratio="16/9"
                >
                        <v-row
                            class="fill-height "
                            align="center"
                            justify="end"
                        >
                            <div align="end" justify="end" class="pa-3" style="margin-top: 200px">
                            <v-img src="../assets/logodarck.png" max-width="320px" contain class="ml-auto"></v-img>
                            <h2 style="color: black">Качественные услуги за разумные деньги</h2>
                            </div>
                        </v-row>


                    </v-img>

            </v-card>
            </v-col>

                </v-row>
    <v-container fluid>
                <!-- <v-row justify="center" align="center">
                    <v-col
                        cols="12"
                        sm="12"
                        md="10">
                        <v-card
                            color="background"
                        >
                            <v-card-title class="text-center align-center justify-center">ДОСТУПНО ПОНЯТНО КАЧЕСТВЕННО</v-card-title>



                            <v-row justify="space-around" align="center">

                                <v-col cols="12" cols-md="6" sm="12" md="6">
                                    <v-card color="background">
                                        <v-card-text justify="space-around" align="center">
                                            <video src="../assets/file.mp4"  autoplay loop muted ></video>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" cols-md="6" sm="12" md="6">
                                    <v-card-text>
                                        EUROKAPPA - инновационная система коррекции зубного ряда и прикуса.​ Качественно, быстро, точный результат.
                                    </v-card-text>
                                    <v-divider light color="#a0796d"></v-divider>

                                    <v-row
                                        align="center"
                                        justify="space-around"
                                        class="ma-4">
                                        <v-btn color="primary" class="justify-center" link to="/news/post/5">Подробрнее</v-btn>
                                    </v-row>

                                </v-col>



                            </v-row>

                        </v-card>
                    </v-col>
                </v-row> -->


                <v-row justify="center" align="center">
                    <v-col
                        cols="12"
                        sm="12"
                        md="10"
                    >

                        <v-card class="mb-3" color="background">
                            <v-card-title class="pa-2 d-flex justify-center">О клинике</v-card-title>
                            <v-card-text class="text-center">
                               <p> Клиника АБРИС существует с 2008 года и  оказывает полный спектр стоматологической помощи.</p>
                                <p>Мы делаем стоматологию ДОСТУПНОЙ, ПОНЯТНОЙ, КАЧЕСТВЕННОЙ!</p></v-card-text>
                            <v-divider light color="#a0796d" ></v-divider>
                            <v-card-actions>


                                <v-row
                                    align="center"
                                    justify="space-around">
                                    <v-btn color="primary" class="justify-center ma-5" link to="/about">Подробрнее</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                    <v-row justify="center" align="center">
                              <v-col
                        cols="12"
                        sm="12"
                        md="10">
                        <v-card
                            color="background"
                            class="pa-2"
                        >

                        <v-card-title class="pa-2 d-flex justify-center">Импланты Medentika производство Германия.</v-card-title>
                        <v-row >
                            <v-col cols="12" sm="6" md="2">
                            <router-link :to="`/service/post/16`">
                              <v-img
                             
                                src="../assets/photo.jpg"
                                height="250px"
                                width="250px"
                                 class="grey darken-4 justify-center"
                                      ></v-img>

                                </router-link >
                            </v-col>
                            <v-col cols="12" sm="6" md="10">
                                    <p>Усовершенствованное протезирование – имплантаты спроектированы и изготовлены в Германии.
                                    Основана в 2005 году в Хюгельсхайме, Германия. Medentika® — ведущий производитель совместимых протезов. Эта быстрорастущая, гибкая инжиниринговая компания делает немецкое качество и точность изготовления доступными </p>

                                    <p>Medentika® поставляет зубным техникам и стоматологам прецизионные имплантаты, многоплатформенные абатменты и компоненты, которые долговечны и полностью совместимы со всеми основными системами имплантатов по доступной цене.
                                    Medentika® стремится к совершенству и точности, поэтому на протезы, совместимые с Medentika®, распространяется пожизненная гарантия.
                                    Многие соединения воспринимаются даже лучше оригинала — они хорошо приняты в лабораториях и стоматологических клиниках по всему миру.</p>
                                </v-col>   
                            </v-row>
                                <v-row >
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4" >Ведущий производитель совместимых устройств</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Ведущий производитель совместимых протезов с многочисленными соединениями, совместимыми со всеми основными системами имплантатов.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4">Сделано в Германии
</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Давняя традиция точного мастерства и промышленного производства.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4 ">Страсть к точности
</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Страсть и стремление к совершенству продуктов и доступ к передовым технологиям.</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <div class="d-flex justify-center align-center ma-3">
                            
                            <v-row >
                                    <v-col cols="12" sm="12" md="12" class="d-flex justify-center align-center ">
                                
  
                                <v-btn  class="mr-3"
                                                  color="primary"
                                                  :to="`/service/post/16`"
                                              >
                                                  Подробнее
                                              </v-btn>
                                            
                                              <v-dialog
                                              width="500px"
                                              v-model="requestDialog"
                                              >
                                          <template v-slot:activator="{on, attrs}">
                                              <v-btn
                                                      color="primary"
                                                      v-bind="attrs"
                                                      v-on="on"
                                              >
                                                  Записаться на прием
                                              </v-btn>
                                          </template>
                                          <app-request @closeRequest="requestDialog = false"></app-request>
                                      </v-dialog>

                                </v-col>
                              </v-row>
  
                          </div>


                        </v-card>
                    </v-row>




                <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="10">
                        <v-row >
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4" >ДОСТУПНО</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Клиника придерживается гибкой ценовой политики.
                                        ​ Цены действительно доступны,  при этом качество лечения - высочайшее.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4">КАЧЕСТВЕННО</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Наши врачи - профессионалы высокого уровня. Оборудование,
                                        материалы и инструменты, которые мы используем, отвечают современным
                                        медицинским требованиям и стандартам.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-card height="250px" hover color="background">
                                    <v-card-title class="mt-4 d-flex justify-center mb-4 ">ПОНЯТНО</v-card-title>
                                    <v-divider light inset color="#a0796d"></v-divider>
                                    <v-card-text>Вам обязательно объяснят на понятном языке, обоснуют  -
                                        что нужно делать именно в вашем случае, почему и
                                        как это сделать максимально эффективно.</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>


                <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="10">
                        <v-card color="background">
                            <v-card-title class="mt-4 d-flex justify-center mb-4 ">Новости Акции События Новинки </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="6" v-for="item in mainPage" :key="item.id">
                                        <v-card min-height="500px" color="background" >
                                            <v-img :src="getUrl+ item.images"
                                                   :lazy-src="getUrl + item.images"
                                                   height="250px"
                                                   aspect-ratio="2"
                                            ></v-img>
                                            <v-card-title>{{item.title}}</v-card-title>
                                            <v-card-text>{{item.preview}}</v-card-text>
                                            <v-card-actions class="justify-center align-center text-center"><v-btn color="primary" :to="`/news/post/${item.id}`">Подробнее</v-btn></v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="12">
                        <v-card color="background">


                            <v-card-title class="justify-center">Дорогие наши клиенты!</v-card-title>
                            <v-divider light color="#a0796d"></v-divider>
                            ​<v-card-text >
                            <p class="text-center">Мы стараемся делать все, чтобы Ваши улыбки были великолепны, а Вы себя прекрасно чувствовали и уносили с собой только приятные впечатления.
                                Это очень здорово - получать благодарности и слышать "Спасибо!"
                                Ваши отзывы (любые!) очень важны для нас, для нашей работы.</p>

                            <p class="text-center"> Будем признательны за объективную обратную связь!  </p>
                        </v-card-text>

                            <v-row class="mt-3">
                                <v-col cols="12" >
                                    <v-card-title class="d-flex justify-center">Отзывы наших клиентов:</v-card-title>

                                                           

                                    

                                <v-card-text class="d-flex justify-center">
                                         <v-btn color="primary" href="https://novosibirsk.flamp.ru/firm/abris_stomatologicheskaya_klinika-141265770549957" class="mr-3">Отзывы Flamp</v-btn><v-btn color="primary" href="https://2gis.ru/novosibirsk/inside/141373143541107/firm/141265770549957/tab/reviews?m=82.93678%2C55.057789%2F19.11">Отзывы 2Gis</v-btn>  

                                        <!-- <a class="flamp-widget hidden" href="//novosibirsk.flamp.ru/firm/abris_stomatologicheskaya_klinika-141265770549957"
                                   data-flamp-widget-type="responsive-new"
                                   data-flamp-widget-id="141265770549957"
                                   data-flamp-widget-width="100%"
                                   data-flamp-widget-count="5">Отзывы о нас на Флампе
                                </a> -->
                            </v-card-text>
                        </v-col>

                    </v-row>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
    !function(d,s){
        var js,fjs=d.getElementsByTagName(s)[0];
        js=d.createElement(s);js.async=1;
        js.src="//widget.flamp.ru/loader.js";
        fjs.parentNode.insertBefore(js,fjs);
    }
    (document,"script");
    import appRequest from "@/components/request/Request";
import {mapGetters, mapActions} from "vuex"
    export default {
        name: "Home",
        data(){
            return {
                requestDialog: false,
                }
            },
        mounted() {
           this.getNewsMainPage(4)
        },
        methods:{
            ...mapActions([
                "getNewsMainPage"
            ])

        },
        computed:{
            ...mapGetters([
                "getUrl",
                "mainPage",
            ])
        },
        components:{
          appRequest
      }


    }
</script>

<style scoped>
    video {
        width: 100%    !important;
        height: auto   !important;
    }

</style>
