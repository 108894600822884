import axios from "axios";
export default {
    state:{
        serviceCategory:[],
        servicePost:[],
        sortServiceCategory:[]
    },
    mutations:{
        setServiceCategory(state,data){
            state.serviceCategory = data
        },
        setServicePost(state,data){
            state.servicePost = data
        },
        setServiceCategorySort(state, data){
            state.sortServiceCategory = data
        }

    },
    actions:{
        async getServiceCategoryApi({commit}){
            await axios.get('/api/service_category')
                .then(response => {
                    commit('setServiceCategory', response.data.data)
                })
                .catch(error => console.log(error))
        },
        async getServicePost({commit}) {
            await axios.get('/api/service')
                .then(response => {
                    commit('setServicePost', response.data.data)
                })
                .catch(error => console.log(error))
        },
        async loadServiceCategory({commit},data){
            const cat_id = data.category_id
            await axios.get(`/api/service/category/${cat_id}`)
                .then(response => {
                    commit('setServiceCategorySort', response.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    },
    getters:{
        getServiceCategory(state){
            return state.serviceCategory

        },
        getServicePost(state){
            return state.servicePost
        },
        getSortServiceCategory(state){
            return state.sortServiceCategory
        }
    },
}
