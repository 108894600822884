export default {
  state: {
    links: [
      {
        name: "Главная",
        route: "/",
      },
      {
        name: "О клинике",
        route: "/about",
      },
      {
        name: "Наши врачи",
        route: "/doctors",
      },
      {
        name: "Услуги",
        route: "/service",
      },
      {
        name: "Акция",
        route: "/News",
      },
      {
        name: "Прайс",
        route: "/price",
      },
      {
        name: " Контакты",
        route: "/contacts",
      },
      {
        name: "Инфо",
        route: "/Info",
      },
    ],
  },
  getters: {
    getLink(state) {
      return state.links;
    },
  },
};
