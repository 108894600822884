import axios from "axios";
export default {
    state:{
        category: [],
        news: [],
        post: [],
        sortNewsCategory:[],
        mainPage:[]
    },
    mutations:{
        setCategory(state, data){
            state.category = data
        },
        setNews(state, data){
            state.news = data
        },
        setNewsPost(state, data){
            state.post = data
        },
        setNewsMainPage(state, data){
            state.mainPage = data
        },
        setNewsCategorySort(state, data){
            state.sortNewsCategory = data
        }


    },
    actions: {
        async getCategoryList({commit}) {

            await axios.get('/api/news_category')
                .then(response => {
                    commit('setCategory', response.data.data)
                })
                .catch(error => { console.log(error)})

        },
        async getNewsAll({commit}) {

            await axios.get('/api/news')
                .then(response =>{
                    commit('setNews', response.data.data)
                })
                .catch(error => { console.log(error)})
        },
      async getNewsPost({commit, dispatch}, id){
            await axios.get(`/api/news/post/${id}`)
                .then(response => {
                    const data = response.data
                    commit('setNewsPost',  data)
                    dispatch('loadNewsCategory',data)
                })
                .catch(error => { console.log(error)})
        },
        async getNewsMainPage({commit}, count){
            axios.get(`/api/news/${count}`)
                .then(response => {
                    commit('setNewsMainPage', response.data)
                })
                .catch(error => console.log(error))
        },
        async loadNewsCategory({commit},data){
                    const cat_id = data.category_id
             await axios.get(`/api/news/category/${cat_id}`)
                 .then(response => {
                     commit('setNewsCategorySort', response.data.data)
                 })
                 .catch(error => {
                     console.log(error)
                 })
        }


    },
    getters:{
        category(state){
            return state.category
        },
        news(state){
            return state.news
        },
        post(state){
            return state.post
        },
        mainPage(state){
            return state.mainPage
        },
        getSortNewsCategory(state){
            return state.sortNewsCategory
        }

    },
}
