<template>
    <v-container fluid>
    <v-row class="justify-center align-center ">
        <v-col cols="12" md="6">
            <v-card color="#F5B93F">
                <v-card-title>Контакты</v-card-title>
            </v-card>
            <v-card color="background">
                <v-card-text style="height: 500px" class="pa-4">
                    <p>Управляющая клиникой -  Бурдастых Юлия Петровна</p>

                    <p>Наш адрес:</p>
                    <p>Новосибирск, ул. Кропоткина 271, офис 509 (5 этаж)</p>

                    <p> e-mail:  <a href="mailto:jla49@mail.ru">jla49@mail.ru</a><p>

                    <p> Телефоны для связи:</p>
                    <p><a href="tel:+79137316530">  +7 913-731-65-30</a></p>
                    <p><a href="tel:83832302008">(383)  230-20-08</a></p>
                    <v-card-actions class="justify-center align-center">

                        <v-dialog
                                width="500px"
                                v-model="requestDialog"
                                >
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    Записаться на прием
                                </v-btn>
                            </template>
                            <app-request @closeRequest="requestDialog = false"></app-request>
                        </v-dialog>
                    </v-card-actions>
                </v-card-text>

            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <v-card color="#F5B93F">
                <v-card-title>Карта</v-card-title>
            </v-card>
            <v-card color="background">
                    <v-card-text style="height: 500px">

                        <yandex-map
                                :coords="coords"
                                zoom=17
                        >
                            <ymap-marker
                                    :coords="coords"
                                    marker-id="1"
                                    :balloon-template="balloonTemplate"

                            />
                        </yandex-map>

                    </v-card-text>

            </v-card>
        </v-col>

    </v-row>
    </v-container>

</template>

<script>
    import appRequest from "../components/request/Request";
    import { yandexMap, ymapMarker } from 'vue-yandex-maps'
    export default {
        name: "Contacts",
        data(){
            return {
                requestDialog: false,
                settings:{
                    apiKey: 'c268a077-ffe2-4555-9fba-102ef283963a',
                    lang: 'ru_RU',
                    coordorder: 'latlong',
                    version: '2.1'
                },
                coords:[55.057907581458664,82.93670505952451],
            }


        },
        methods:{

        },
        computed: {
            balloonTemplate() {
                return `
                <v-card>
                <v-card-title>Стоматология Абрис</v-card-title>


                <v-card-text style="height: 500px" class="pa-4">

                    <p>Управляющая клиникой -  Бурдастых Юлия Петровна</p>

                    <p>Наш адрес:</p>
                    <p>Новосибирск, ул. Кропоткина 271, офис 509 (5 этаж)</p>

                    <p> e-mail:  <a href="mailto:jla49@mail.ru">jla49@mail.ru</a><p>

                    <p> Телефоны для связи:</p>
                    <p><a href="tel:+79137316530">  +7 913-731-65-30</a></p>
                    <p><a href="tel:83832302008">(383)  230-20-08</a></p>
                </v-card-text>
            </v-card>

`
            }

        },
        components:{
            appRequest,
            yandexMap,
            ymapMarker
        }
    }
</script>

<style scoped>
    a{
        text-decoration: none;
    }
    .ymap-container {
        height: 100%;
    }

</style>
