import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueYandexMetrika from 'vue-yandex-metrika' 
Vue.config.productionTip = false

Vue.use(VueYandexMetrika, {
  id: 89317458,
  router: router,
  env: process.env.NODE_ENV,
  options:{
	triggerEvent:true,
	clickmap:true,
	trackLinks:true,
	accurateTrackBounce:true,
	webvisor:true,
	}  // other options
})



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
