import Vue from 'vue'
import Vuex from 'vuex'
import menu from "./menu";
import news from "./news";
import price from "./price";
import service from "./service";
import galleries from "./galleries";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      navDrawer: false,
      url: 'http://abris-stom.com/'
  },
  mutations: {
      setNavDrawer(state){
          state.navDrawer = !state.navDrawer
      }
  },
  actions: {
      ToogleNavDrawer({commit}){
          commit('setNavDrawer')
      }
  },
    getters:{
      getNavDrawer(state){
          return state.navDrawer
      },
      getUrl(state){
          return state.url
      }
    },
  modules: {
      menu,
      news,
      price,
      service,
      galleries
  }
})
