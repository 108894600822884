<template>
    <v-container fluid>
        <v-row>
            <v-col xs="12" sm="12" md="12">

                    <v-card class="mb-3" color="background">
                        <v-card-title class="justify-center align-center">{{post.title}}</v-card-title>
                        <v-card-actions class="justify-space-between">
                            <v-icon @click="$router.go(-1)
                                 ">mdi-arrow-left</v-icon>

                        <span>{{post.updated_at}}</span>

                        </v-card-actions>
                    </v-card>

                    <v-card color="background">

                        <v-card-title>
                        <v-row justify="center" align="center">
                            <v-col sm="12" md="8" class="align-center justify-center">
                                
                            <v-img :src="getUrl + post.images"
                                   :lazy-src="getUrl + post.images"
                                    max-width="800"
                                
                                   aspect-ratio="2"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            </v-col>
                        </v-row>
                        </v-card-title>
                        <v-row align="center" justify="center" >
                            <v-col sm="12" md="8">
                        <v-card-text v-html="post.content_post">
                       </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>



            </v-col>

        </v-row>
    </v-container>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import axios from "axios";

    export default {
        name: "NewsPost",
        data(){
            return{
                post: null
            }
        },
        mounted() {
            this.getPostById(this.$route.params.id)
        },
        methods:{
            hasHistory () {
                return window.history.length > 2
            },

            async getPostById(id){
                this.post = await axios.get(`/api/news/post/${id}`)
                    .then(response => {return response.data})
                    .catch(error => console.log(error))
            },


        },

        computed:{
            ...mapGetters([
                "getSortNewsCategory",
                "getUrl",
                "post"
            ]),
        },
        watch:{

        }


    }
</script>

<style scoped>


</style>
